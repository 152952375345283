<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> CONDITIONS GÉNÉRALES DE VENTE ET D'UTILISATION </h4>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->


    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">

                          <h4>Article #1 : Définitions</h4>
                          <p>
                            On désignera par la suite :
                            <ul>
                              <li>'Site' : le site http://cutill.co et l'ensemble de ses pages.</li>
                              <li>'Produits' ou 'service' : L'application Cutill disponible sur les stores Apple et Google</li>
                              <li>'Editeur' : La personne, morale ou physique, responsable de l'édition et du contenu du site.</li>
                              <li>'Utilisateur' : L'internaute utilisant le site ou l'un de ses Produits.</li>
                              <li>'Client': l'utilisateur inscrit abonné ou non abonnée à l'application Cutill.</li>
                            </ul>
                          </p>

                          <h4>Article #2 : Mentions imposées par la loi de confiance dans l’économie numérique et objet du site</h4>
                          <p>
                          Le présent site est édité par KMEDINI Auto entrepreneur. Les informations légales concernant l'hébergeur et l'éditeur du site, notamment les coordonnées et les éventuelles informations de capital et d'immatriculation, sont fournies dans les mentions légales du présent site. L'objet du présent site est déterminé comme "logiciel de caisse enregistreuse". Le site est d’accès libre et gratuit à tout internaute. L’acquisition d’un bien ou d’un service, ou la création d’un espace membre, suppose l’acceptation, par l’internaute, de l’intégralité des présentes conditions générales, qui reconnaît du même fait en avoir pris pleinement connaissance.
                          L’acceptation des conditions générales de ventes et d’utilisation se fait dès lors que l’utilisateur passe l’étape d’information relatives à la confidentialité des données. L’utilisateur peut à tout moment lire les conditions générales de ventes et d’utilisation dans la rubrique d’aide.
                          Le fait de cocher cette case sera réputé avoir la même valeur qu’une signature manuscrite de la part de l’internaute. L’internaute reconnaît la valeur de preuve des systèmes d’enregistrement automatique de l’éditeur du présent site et, sauf pour lui d’apporter une preuve contraire, il renonce à les contester en cas de litige. L’acceptation des présentes conditions générales suppose de la part des utilisateurs qu’ils jouissent de la capacité juridique nécessaire pour cela. Si l'utilisateur est mineur ou ne dispose pas de cette capacité juridique, il déclare avoir l’autorisation d’un tuteur, d’un curateur ou de son représentant légal.
                          Cutill ne collecte aucune information à caractère personnel du client. Des données non nominatives sont collectées à des fins statistiques, dans le but unique d’améliorer le service. Aucune donnée n’est commercialisée. La politique de confidentialité des données fait partie des CGUV. L'acceptation des présentes CGUV implique par conséquent l'acceptation de la politique de confidentialité des données.
                          </p>
                          <h4>Article #3 : Les services proposés</h4>
                          <p>Les services proposés sont disponible d’accès en version gratuite directement sur l’application Cutill et permettent de prendre connaissance des caractéristiques.
                          Le service d'assistance du présent site est accessible par courrier électronique à l’adresse suivante : contact@cutill.co ou par courrier postal à l’adresse indiquée dans les mentions légales.
                          </p>
                          <h4>Article #4 : Tarifs</h4>
                          <p>
                            Les prix figurant dans l’application sont des prix entendus en toutes taxes comprises (TTC) de la monnaie locale de l’utilisateur, tenant compte de la taxe applicable au jour de la commande. Cutill se réserve le droit de répercuter tout changement du taux de taxe sur le prix des services. L'éditeur se réserve également le droit de modifier ses prix à tout moment. Néanmoins, le prix figurant sur l’application le jour de la commande sera le seul applicable à l’acheteur.
                          Article #5 : Espace membre ou utilisateur
                          L’utilisation de Cutill ne requiert aucun compte utilisateur ni inscription. Par conséquent, aucune information n’est sauvegardé toutes les informations renseignées par l’utilisateur sont accessible uniquement par celui-ci. L’utilisateur est seul responsable de la conservation et d'archivage des données en vue du contrôle de l'administration fiscale.
                          Une fois téléchargé sur l’appareil de l’utilisateur, les données enregistrés par l’utilisateur sont enregistrée dans l’appareil uniquement. Cutill assure la sécurité et l'inaltérabilité des données renseignées par l’utilisateur dans le logiciel.
                          L'utilisateur est l’unique responsable de la sécurisation des données renseignées dans l’application Cutill. Il prendra les mesures nécessaires pour vérrouiller l’accès à l’application, notamment via mot de passe, code PIN. Cutill ne pourra être tenu pour responsable des accès non autorisés au compte de l’utilisateur.

                          Si les données contenues dans la rubrique compte membre venaient à disparaître à la suite d’une panne technique ou d’un cas de force majeure, la responsabilité du site et de son éditeur ne pourrait être engagée, ces informations n’ayant aucune valeur probante mais uniquement un caractère informatif. Les pages relatives aux comptes membres ne constituent nullement une preuve, elles n’ont qu’un caractère informatif destiné à assurer une gestion efficace des souscriptions et des éventuelles contributions du membre. L’éditeur se réserve le droit exclusif de supprimer le compte de tout membre qui aurait contrevenu aux présentes conditions générales (notamment, mais sans que cet exemple n’ait un quelconque caractère exhaustif, lorsque le membre aura fourni sciemment des informations erronées lors de son inscription et/ou de la constitution de son espace personnel) ou encore tout compte inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer un dommage pour le membre exclu qui ne pourra prétendre à aucune indemnité de ce fait. Cette exclusion n’est pas exclusive de la possibilité, pour l’Editeur, d’entreprendre des poursuites d’ordre judiciaire à l’encontre du membre, lorsque les faits l’auront justifié.
                          </p>
                          <h4>Article #6 : Exonération de la responsabilité de l’éditeur dans le cadre de l’exécution du présent contrat</h4>
                          <p>
                            En cas d’impossibilité d’accès au site, en raison de problèmes techniques ou de toutes natures, l'Utilisateur ne pourra se prévaloir d’un dommage et ne pourra prétendre à aucune indemnité. L’indisponibilité, même prolongée et sans aucune durée limitative, d’un ou plusieurs produits, ne peut être constitutive d’un préjudice pour les internautes et ne peut aucunement donner lieu à l’octroi de dommages et intérêts de la part du site ou de son éditeur. Les photographies et visuels des produits présentés sur le site n’ont aucun caractère contractuel, la responsabilité de l’éditeur du présent site ne saurait donc être engagée si les caractéristiques des objets diffèrent des visuels présents sur le site ou si ces derniers sont erronés ou incomplets. Les liens hypertextes présents sur le présent site peuvent renvoyer sur d’autres sites internet et la responsabilité de l’éditeur du présent site ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même la responsabilité de l’éditeur du présent site ne saurait être engagée si la visite, par l’internaute, de l’un de ces sites, lui causait un préjudice.
                          </p>
                          <h4>Article #7 : Droits de propriété intellectuelle relatifs aux éléments publiés sur le présent site</h4>
                          <p>
                            Tous les éléments du présent site appartiennent à l'éditeur ou à un tiers mandataire, ou sont utilisés par l'éditeur sur le site avec l'autorisation de leur propriétaire. Toute copie des logos, contenus textuels, pictographiques ou vidéos, sans que cette énumération ne soit limitative, est rigoureusement interdite et s’apparente à de la contrefaçon. Tout membre qui se rendrait coupable de contrefaçon serait susceptible de voir son compte supprimé sans préavis ni indemnité et sans que cette suppression ne puisse lui être constitutive d’un dommage, sans réserve d’éventuelles poursuites judiciaires ultérieures à son encontre, à l’initiative de l’éditeur du présent site ou de son mandataire.
                          </p>
                          <h4>Article #8 : Marques</h4>
                          <p>
                            Les marques et logos contenus dans le site sont déposés par KMEDINI, ou éventuellement par un de ses partenaires.  Toute personne procédant à leurs représentations, reproductions, imbrications, diffusions et rediffusions encourt les sanctions prévues aux articles L.713-2 et suivants du Code de la propriété intellectuelle.
                          </p>
                          <h4>Article #9 : Limitation de responsabilité</h4>
                          <p>
                            Tout incident causant la perte des données (ex : perte, casse, vol, bug... ) est de la responsabilité de l’utilisateur. Cutill met à disposition un système de sauvegarde, via la fonction d’export de données. L’utilisateur se doit d’effectuer quotidiennement des sauvegardes afin de ne pas perdre les données associées à son appareil.
                          Cutill ne pourra être tenu responsable de la perte des données de l’utilisateur.

                          L'éditeur du site, notamment dans le processus de vente en ligne, n’est tenu que par une obligation de moyens ; sa responsabilité ne pourra être engagée pour un dommage résultant de l’utilisation du réseau Internet tel que perte de données, intrusion, virus, rupture du Service ou autres. Cutill ne saurait être tenu pour responsable de l'inexécution du contrat conclu, due à la survenance d'un événement de force majeure et notamment en cas de grève totale ou partielle de services externes ou de catastrophes causées par des inondations ou des incendies. Concernant les produits achetés, l'éditeur n'encourra aucune responsabilité pour tous dommages indirects du fait des présentes, perte d'exploitation, perte de profit, dommages ou frais, qui pourraient survenir. Le choix et la souscription à un Produit sont placés sous l'unique responsabilité de l'utilisateur. L'utilisateur admet expressément utiliser le site à ses propres risques et sous sa responsabilité exclusive. Le site fournit à l'utilisateur des informations à titre indicatif, avec des imperfections, erreurs, omissions, inexactitudes et autres ambivalences susceptibles d'exister. En tout état de cause, l'éditeur ne pourra en aucun cas être tenu responsable :- de tout dommage direct ou indirect, notamment en ce qui concerne les pertes de profits, le manque à gagner, les pertes de clientèle, de données pouvant entre autres résulter de l'utilisation du site, ou au contraire de l'impossibilité de son utilisation ;- d'un dysfonctionnement, d'une indisponibilité d'accès, d'une mauvaise utilisation, d'une mauvaise configuration de l'ordinateur de l'utilisateur, ou encore de l'emploi d'un navigateur peu usité par l'utilisateur ;- du contenu des publicités et autres liens ou sources externes accessibles par l'utilisateur à partir du site
                          </p>
                          <h4>Article #10 : Accès au site</h4>
                          <p>
                            La responsabilité de l'éditeur du site ne peut être engagée en raison d'une indisponibilité technique de la connexion, qu'elle soit due notamment à un cas de force majeure, à une maintenance, à une mise à jour, à une modification du site, à une intervention de l'hébergeur, à une grève interne ou externe, à une panne de réseau, à une coupure d'alimentation électrique, ou encore à une mauvaise configuration ou utilisation de l'ordinateur de l'utilisateur.
                          </p>
                          <h4>Article #11 : Fermeture de compte</h4>
                          <p>
                            Vous pouvez fermer votre compte en envoyant un email à contact@cutill.co. Vos données seront conformément à la réglementation en vigueur préservé et inaltérable dans une durée de 6ans.
                          </p>
                          <h4>Article #12 : Droit applicable et médiation</h4>
                          <p>
                            Les présentes conditions générales sont soumises à l’application du droit Français. Elles peuvent être modifiées à tout moment par l’éditeur du site ou son mandataire. Les conditions générales applicables à l’utilisateur sont celles en vigueur au jour de sa souscription à un service sur le présent site. L’éditeur du site s’engage à conserver les anciennes versions des présentes conditions générales en cas d'évolution et à les faire parvenir à tout Utilisateur qui en ferait la demande.
                          Sauf dispositions d’ordre public, tous litiges qui pourraient survenir dans le cadre de l’exécution des présentes conditions générales pourront avant toute action judiciaire être soumis à l’appréciation de l’éditeur du site en vue d’un règlement amiable. Il est expressément rappelé que les demandes de règlement amiable ne suspendent pas les délais ouverts pour intenter les actions judiciaires. Sauf disposition contraire, d’ordre public, toute action judiciaire relative à l’exécution du présent contrat devra être soumise à la compétence des juridictions du ressort de la Cour d’appel saisie.
                          Médiation de la consommation
                          Selon l'article L.612-1 du Code de la consommation, il est rappelé que "tout consommateur a le droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable du litige qui l’oppose à un professionnel. A cet effet, le professionnel garantit au consommateur le recours effectif à un dispositif de médiation de la consommation".
                          A ce titre Cutill propose à ses clients consommateurs, dans le cadre de litiges qui n'auraient pas trouvé résolution de manière amiable, la médiation d'un médiateur de la consommation, dont les coordonnées sont les suivantes :
                          Médiateur du centre de médiation agréé Médicys
                          contact@medicys.fr
                          http://www.medicys.fr/index.php/consommateurs/
                          Il est rappelé que la médiation n'est pas obligatoire mais uniquement proposée afin de résoudre les litiges en évitant un recours à la justice.
                          </p>
                          <h4>Article #13 : Utilisation de Cookies</h4>
                          <p>
                            Un « Cookie » permet l'identification de l'utilisateur d'un site, la personnalisation de sa consultation du site et l'accélération de l’affichage du site grâce à l'enregistrement d'un fichier de données sur son ordinateur. Le site est susceptible d'utiliser des « Cookies » principalement pour 1) obtenir des statistiques de navigation afin d'améliorer l'expérience de l'Utilisateur, et 2) permettre l'accès à un compte de membre et à du contenu qui n'est pas accessible sans connexion. L'Utilisateur reconnaît être informé de cette pratique et autorise l'éditeur du site à y recourir. L'éditeur s'engage à ne jamais communiquer le contenu de ces « Cookies » à des tierces personnes, sauf en cas de réquisition légale. L'Utilisateur peut refuser l'enregistrement de « Cookies » ou configurer son navigateur pour être prévenu préalablement à l'acception les « Cookies ». Pour ce faire, l'Utilisateur procédera au paramétrage de son navigateur :- Pour Internet Explorer : http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies- Pour Safari : https://support.apple.com/fr-fr/ht1677- Pour Google Chrome : https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on- Pour Firefox : https://support.mozilla.org/fr/kb/activer-desactiver-cookies- Pour Opera : http://help.opera.com/Windows/10.20/fr/cookies.html
                          </p>
                          <h4>Article #14 : Modalité de souscription au service et descriptif du processus d’achat</h4>
                          <p>
                            On définira ci-dessous comme « Panier » l’objet immatériel (par exemple une page ou partie de page du site) regroupant l’ensemble des Produits sélectionnés par l’utilisateur en vue d’un achat ou abonnement. Dès lors que l’internaute estimera avoir sélectionné et ajouté à son panier tous les produits qu’il souhaite acheter, il aura la possibilité, pour valider sa commande, d’accéder à une page récapitulative sur laquelle lui seront communiqués les caractéristiques (notamment la volumétrie et d'éventuelles options) des produits commandés, ainsi que leur prix unitaire, ou le prix de l'abonnement selon la nature de la tarification du service. S’il souhaite valider sa commande, l’utilisateur devra cliquer sur le bouton de validation. Le paiement s’effectuera directement depuis l’application via les outils de facturation des plateformes Google et Apple.
                          Le compte bancaire du client sera débité en début de chaque période.
                          Le prix de vente du produit sera celui indiqué sur lors du processus d’achat/d’abonnement.

                          Pour le paiement d’achats intégrés à une Application ou la souscription à un Abonnement depuis un Store, l’Utilisateur doit avoir exclusivement recours à la Solution de paiement sécurisé proposée par le Store en se connectant depuis son Compte. Pour ce faire, l’Utilisateur reconnaît et accepte devoir préalablement accepter les conditions générales de l’éditeur de la Solution de paiement sécurisé, lesquelles imposent des exigences préalables au paiement.

                          Elles sont consultables depuis les liens suivants :
                          pour l'App Store: https://www.apple.com/legal/internet-services/itunes/fr/terms.html
                          pour Google Play: https://play.google.com/intl/fr_fr/about/play-terms.html
                          A défaut de se conformer aux conditions générales de l’éditeur de la Solution de paiement sécurisé, l’Utilisateur reconnaît et accepte qu’il ne puisse commander le Service ou que le Service commandé soit automatiquement annulé, sans qu’aucune indemnité d’aucune sorte ne lui soit allouée.

                          Tout Abonnement est conclu pour une durée déterminée qui est renouvelable par tacite reconduction pour la même durée, sauf résiliation avant la date d’échéance ou le cas échéant désactivation à du renouvellement automatique de l’Abonnement dans les conditions prévues à l’article “Résiliation”.

                          Dans le cas où la souscription à un Abonnement serait assortie d’une période d’essai gratuite, l’Abonnement de l’Utilisateur ne deviendra payant que le lendemain de l’expiration de la période d’essai gratuite. Si l’Utilisateur souhaite mettre fin à la période d’essai gratuite, il doit résilier son Abonnement 24 heures avant la fin de la période d’essai gratuite dans les conditions prévues à l’article “Résiliation”.

                          Pour souscrire à un Abonnement depuis l’Application, l’Utilisateur doit choisir la durée de son Abonnement parmi les durées d’Abonnement qui lui sont proposées et confirmer son achat.
                          L’Utilisateur paie son Abonnement via la Solution de paiement sécurisé proposé par le Store après s’être connecté à son Compte.
                          Le paiement de la première période de l’Abonnement s’effectue lors de sa souscription et l’Utilisateur sera prélevé lors de chaque renouvellement par le biais de la Solution de paiement sécurisé du Store sauf résiliation de l’Abonnement dans les conditions prévues à l’article  l’article “Résiliation”.

                          L’Abonnement est automatiquement renouvelé à moins qu’il ne soit annulé depuis le Store au moins 24 heures avant la fin de la période d’Abonnement en cours.
                          En outre, le renouvellement d’un Abonnement souscrit depuis un Store sera facturé dans les 24 heures avant la fin de la période en cours.

                          L’achat de crédit est possible depuis l’application. La durée de validité d’un crédit acheté sur l’application est d’1 an à compter de la date d’achat. Au delà, le crédit n’est plus valide dans l’application.
                          </p>
                            <h4>Article #15 : Informations relatives au paiement</h4>
                            <p>L’internaute peut passer commande sur le présent site et peut effectuer son règlement par carte bancaire. Les paiements par carte bancaire se font au moyen de transactions sécurisées fournies par un prestataire de plateforme de paiement en ligne. Le paiement est effectué directement entre les mains de la banque ou du prestataire de paiement recevant le paiement du Client.
                            </p>
                              <h4>Article #16 : Mise à disposition</h4>
                            <p>La mise à disposition du service (autrement appelée sa livraison) est immédiate, ou nécessite un délai court. Ce délai ne pourra cependant excéder 7 jours. Toute réclamation non effectuée dans les règles définies dans la section dédiée des présentes conditions générales et dans les délais impartis ne pourra être prise en compte et dégagera la société KMEDINI de toute responsabilité vis à vis de l’acheteur. A réception d'une réclamation valide, la société KMEDINI communiquera par email, par fax ou par téléphone à l’acheteur les modalités de remboursement ou de modification de la commande.
                            </p>
                          <h4>Article #17 : Résiliation</h4>
                            <p>En conformité avec l'article L.221-28 du Code de la consommation, Cutill fournissant l'accès à un outil en ligne, à facturation initiale et/ou régulière selon des modalités précisées dans la section précisant les conditions d'accès au logiciel des présentes conditions générales, le site est éligible à la rétractation, mais celle-ci ne peut entraîner le remboursement de la somme déjà versée pour la période déjà écoulée. Il s'agira donc d'une résiliation, avec annulation du contrat et perte des sommes versées. Et l'acceptation des présentes conditions entraîne l'acceptation de la perte, de ce fait, du droit de rétractation légal établi au sus-dit article. Dans l’hypothèse d’une résiliation, l’application informera le Client de la résiliation de son abonnement. L'impossibilité totale ou partielle d'utiliser le Service notamment pour cause d'incompatibilité du matériel ne peut donner lieu à aucun dédommagement, remboursement ou mise en cause de la responsabilité de l'éditeur, ou d'exercice du droit de rétractation. En cas de non paiement total ou partiel du produit, Cutill pourra suspendre l’accès à l’application.
                          Cutill se réserve le droit de désactiver sans préavis et sans notification préalable tout client en cas de défaut de paiement lors de l'exécution ou du renouvellement du contrat.
                          La résiliation d’un abonnement effectué par le client se fait directement dans l’application Cutill ou via la gestion des abonnements des Stores (Apple et Google). La résiliation prend effet à la fin de l’échéance de l'abonnement en cours.
                          Pour désactiver le renouvellement automatique de son Abonnement, le résilier ou le modifier, l’Utilisateur doit se référer aux conditions applicables par le Store et peut trouver les informations aux liens suivants :
                          Pour Apple: https://support.apple.com/fr-fr/ht202039
                          Pour Google: https://support.google.com/googleplay/answer/7018481?hl=fr&co=GENIE.Platform%3DAndroid

                          La résiliation d’un plan s’effectue via un changement d’abonnement (“Downgrade”/”Upgrade”).
                          Lors d’un changement d’un abonnement payant à un autre abonnement payant, les privilèges associés à l’abonnement résilié sont immédiatement révoqué.
                          Lors d’un changement d’un abonnement payant à un abonnement gratuit, les privilèges associés à l’abonnement résilié sont révoqué à la date d’échéance de l’abonnement en cours. Le passage à l’abonnement gratuit se fait automatiquement à la date d’échéance de l’abonnement en cours.
                            </p>
                          <h4>Article #18 : Archivage</h4>
                            <p>Cutill archivera les bons de commandes et les factures sur un support fiable et durable constituant une copie fidèle. Les registres informatisés seront considérés par les parties comme preuve des communications, commandes, paiements et transactions intervenus entre les parties
                            </p>
                          <h4>Article #19 : Conditions d’accès à la solution</h4>
                            <p>Le présent site met à disposition de l'Utilisateur une solution hors ligne. Les différents programmes de solutions proposés et les conditions d’abonnement correspondants sont présentés sur les pages d'offres de l’application. Selon l’offre choisie, l’application communiquera à l'utilisateur la durée de validité de son abonnement. Le site concède à l'utilisateur un droit personnel, non exclusif, non cessible et non transférable d’utilisation des solutions, pendant toute la durée du contrat et pour le monde entier. L'utilisateur ne peut utiliser les services applicatifs et les solutions que conformément à ses besoins. En particulier, la licence relative aux solutions n’est concédée que dans le seul et unique but de permettre à l'utilisateur l’utilisation des services, à l’exclusion de toute autre finalité. Le droit d’utilisation s’entend du droit de représenter et de mettre en œuvre les services applicatifs conformément à leur destination, en mode SaaS ("Software as a Service", logiciel en tant que service) via une connexion ou non à un réseau de communications électroniques. L'utilisateur ne pourra en aucun cas mettre les solutions à disposition d’un tiers, et s’interdit strictement toute autre utilisation, en particulier toute adaptation, modification, traduction, arrangement, diffusion, décompilation, sans que cette liste soit limitative. Si le site met en place un engagement minimum de souscription pour certaines de ses offres, celui-ci sera stipulé clairement et distinctement sur la page de l'offre et lors du processus de souscription.
                            </p>
                          <h4>Article #20 : Paiement</h4>
                            <p>Les différents programmes de solutions proposés et leurs prix d’abonnement correspondants sont présentés sur les pages d'offres ou de tarifs de l’application.
                          L’application précise sur ses pages si la facturation est à la période (à la journée, au mois, à l’année, toute période commencée étant due), ou bien fixée selon un niveau d’utilisation des ressources (toute unité de ressource entamée étant due), ou tout autre voie d’accès, précisé sur cette dite page.
                          Le mode de facturation est de même précisé sur les pages d'offres ou de tarifs de l’application, ou à défaut dans la section concernant les conditions d’accès à la solution de ces conditions générales.
                          En cas de prélèvement automatique, l’application précisera au client dans l’interface de paiement la date du prélèvement, et son montant, ainsi que la fréquence, celle-ci pouvant dépendre de chaque client.</p>

                          <h4>Article #21 : Restitution des données</h4>
                          <p>
                            En cas de cessation de la relation contractuelle, quelle qu’en soit la cause, l'éditeur de l’application ne peut restituer les données de l’utilisateur, seul responsable. KMEDINI n’ayant pas accès à ces données, il ne peut restituer les données de l’utilisateur.
                          </p>
                            <h4>Article #22 : Encadrement des conditions</h4>
                            <p>Si une disposition des présentes conditions générales est jugée illégale, nulle ou pour toute autre raison inapplicable, alors cette disposition sera réputée divisible des conditions générales et n'affectera pas la validité et l'applicabilité des dispositions restantes. Ces présentes conditions décrivent l’ensemble de l’accord entre l’utilisateur et l’application. Elles remplacent tous accords antérieurs ou contemporains écrits ou oraux. Les conditions générales ne sont pas cessibles, transférables ou sous-licenciables par l’utilisateur lui-même. Les parties conviennent que toute la correspondance relative à ces conditions générales doit être rédigée en langue française.</p>

                          <h4>Article #23 : Notifications</h4>
                          <p>Toute notification ou avis concernant les présentes conditions générales, les mentions légales ou la charte de données personnelles doit être faite par écrit et doit être remis en mains propres, courrier recommandé ou certifié, par Poste ou tout autre service de messagerie reconnu au niveau national qui permet de suivre régulièrement ses forfaits, ou encore par mail aux adresses indiquées dans les mentions légales du site, en précisant vos noms, prénoms, coordonnées et objet de l’avis.
                          </p>
                          <h4>Article #24 : Inexactitudes</h4>
                          <p>Il peut être possible que se trouvent, dans l’ensemble de l’application et des services proposés, et dans une mesure restreinte, des inexactitudes ou des erreurs, ou des informations qui soient en désaccord avec les conditions générales, les mentions légales ou la charte de données personnelles. En outre, il est possible que des modifications non autorisées soient faites par des tiers sur le site ou sur des services annexes (réseaux sociaux…). Nous mettons tout en œuvre pour que ce genre d’écarts soient corrigés. Dans le cas où une telle situation nous échapperait, merci de nous contacter par mail aux adresses indiquées dans les mentions légales du site avec, si possible, une description de l’erreur et l'emplacement (URL), ainsi que des informations suffisantes pour nous permettre de vous contacter. Pour les demandes portant sur le droit d'auteur, merci de vous référer à la section sur la propriété intellectuelle.
                          </p>
                          <h4>Article #25 : Réclamations</h4>
                          <p>Toute réclamation liée à l'utilisation de l’application, de ses services ou de tout autre service lié, les pages du site sur des réseaux sociaux éventuels ou les conditions générales, mentions légales ou charte de données personnelles doit être déposée dans les 365 jours suivant le jour d’origine du problème source de réclamation, et ce indépendamment de toute loi ou règle de droit contraire. Dans le cas où une telle réclamation n’aurait pas été déposée dans les 365 jours suivants, une telle réclamation sera à jamais inapplicable en justice.
                            Tous droits réservés</p>

                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
